import { Beneficiary } from "@/models/utils/beneficiary.interface";
import { ApiService } from "vuelando";

const controller = "exceldatamanager";

class ExcelDataManagerService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetDataByExcel(file: File) {
    const formData = new FormData();
    formData.append("file", file);
    return this.post<FormData, Beneficiary[]>(
      `${controller}/getDataByExcel`,
      formData
    );
  }
}

export const excelDataManagerService = new ExcelDataManagerService();
