import { GenerateXml } from "@/models/entities/generate-xml.interface";
import { ApiService } from "vuelando";

const controller = "downloadxml";

class GenerateXmlService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  GetDownloadXml(generateXml: GenerateXml, firstTime: boolean) {
    generateXml.firstTime = firstTime;
    return this.post<GenerateXml, string>(
      `${controller}/downloadXML`,
      generateXml
    );
  }
}

export const downloadXmlService = new GenerateXmlService();
