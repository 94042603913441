import { BillingAddress } from "@/models/entities/billing-address.interface";
import { ChangePassword } from "@/models/entities/change-password";
import { PersonalDataChange } from "@/models/entities/personal-data-change.interface";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import { ApiService } from "vuelando";

const controller = "user";

class UserService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }
  GetPersonalData() {
    return this.getAll<PersonalDataChange>(`${controller}/GetPersonalData`);
  }
  updatePersonalData(personalDataChange: PersonalDataChange) {
    return this.post<PersonalDataChange, void>(
      `${controller}/updatePersonalData`,
      personalDataChange
    );
  }
  CheckPassword(oldPassword: string) {
    return this.getAll<boolean>(
      `${controller}/CheckPassword?oldPassword=${oldPassword}`
    );
  }
  ChangePassword(password: ChangePassword) {
    return this.post<ChangePassword, void>(
      `${controller}/ChangePassword`,
      password
    );
  }
  ResetPassword() {
    return this.getAll<void>(`${controller}/ResetPassword`);
  }
  GetSubscription() {
    return this.getAll<PromotionPlans>(`${controller}/getsubscription`);
  }
  subscriptionDuration() {
    return this.getAll<number>(`${controller}/subscriptionduration`);
  }
  changeAccountStatusByID(status: boolean) {
    return this.post<number, void>(
      `${controller}/changeAccountStatusByID/${status}`,
      1
    );
  }
  GetFuturePlan() {
    return this.getAll<number>(`${controller}/getFuturePlan`);
  }
  GetBillingAddressByID() {
    return this.getAll<BillingAddress>(`${controller}/getBillingAddressById`);
  }
  UpdateBillingAddress(billingAddress: BillingAddress) {
    return this.post<BillingAddress, void>(
      `${controller}/updatebillingaddress`,
      billingAddress
    );
  }
  GetLogicallyDeletedById() {
    return this.getAll<boolean>(`${controller}/getLogicallyDeletedById`);
  }
  PhysicalDeleteByID() {
    return this.post<number, void>(`${controller}/physicalDeleteByID`, 1);
  }
  GetConsumptionLocked() {
    return this.getAll<boolean>(`${controller}/getConsumptionLocked`);
  }
}

export const userService = new UserService();
