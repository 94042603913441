import { NextPlan } from "@/models/entities/next-plan.interface";
import { OrderingSubjectView } from "@/models/entities/ordering-subject-view.interface";
import { OrderingSubject } from "@/models/entities/ordering-subject.interface";
import { SubjectAndRole } from "@/models/entities/subject-role.interface";
import { ApiService } from "vuelando";

const controller = "orderingsubject";

class OrderingSubjectService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  readAll(UserId: number) {
    return this.getAll<OrderingSubjectView[]>(`${controller}/${UserId}`);
  }

  getAllById(orderingSubjectID: number) {
    return this.getAll<OrderingSubject>(
      `${controller}/getById?OrderingSubjectID=${orderingSubjectID}`
    );
  }

  addOrderingSubjects(orderingSubject: OrderingSubject) {
    return this.post<OrderingSubject, number>(
      `${controller}/save`,
      orderingSubject
    );
  }

  deleteOrderingSubjects(idOrderingSubject: number) {
    return this.getAll<void>(`${controller}/delete/${idOrderingSubject}`);
  }

  getRoleAndTotSubject() {
    return this.getAll<SubjectAndRole>(`${controller}/getRoleAndTotSubject`);
  }

  getNextPlan(oldTotalSubject: number) {
    return this.getAll<NextPlan>(
      `${controller}/getNextPlan?oldTotalSubject=${oldTotalSubject}`
    );
  }
}

export const orderingSubjectService = new OrderingSubjectService();
