import { NumTransfersByYear } from "@/models/entities/num-transfers-by-year.interface";
import { totalOrderingSubjectByUser } from "@/models/entities/total-ordering-subject-by-user.interface";
import { TransfersUsed } from "@/models/entities/transfers-used.interface";
import { ApiService } from "vuelando";

const controller = "statistics";

class OverviewService extends ApiService {
  constructor() {
    super(process.env.VUE_APP_API_ENDPOINT);
  }

  getOrderingSubjects() {
    return this.getAll<totalOrderingSubjectByUser>(
      `${controller}/getOrderingSubjects`
    );
  }

  GetNumTransfersByMonth() {
    return this.getAll<TransfersUsed>(`${controller}/getNumTransfersByMonth`);
  }

  getNumTransfers(year: number) {
    return this.getAll<Array<NumTransfersByYear>>(
      `${controller}/getNumTransfersByYear?year=${year}`
    );
  }

  getSubscriptionYears() {
    return this.getAll<number>(`${controller}/getSubscriptionYearsByID`);
  }
}
export const overviewService = new OverviewService();
