
import { OrderingSubjectView } from "@/models/entities/ordering-subject-view.interface";
import { CategoryPurpose } from "@/models/entities/category-purpose.interface";
import { GenerateXml } from "@/models/entities/generate-xml.interface";
import { orderingSubjectService } from "@/services/api/ordering-subject.service";
import { downloadXmlService } from "@/services/api/generate-xml.service";
import { categoryPurposeService } from "@/services/api/category-purpose.service";
import { excelDataManagerService } from "@/services/api/excel-data-manager.service";
import store from "@/store";
import AuthModule from "@/store/modules/auth.module";
import SearchBarModule from "@/store/modules/searchBar.module";
import OverlayModule from "@/store/modules/overlay.module";
import { Component, Vue, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { Beneficiary } from "@/models/utils/beneficiary.interface";
import {
  CellType,
  DataTable,
  Header,
  SnackbarModule,
  TableAction,
  ZFileInput,
} from "vuelando";
import { required, generalFiscalCode } from "@/helpers/rules";
import PageTitleModule from "@/store/modules/pageTitle.module";
import { BreadCrumb } from "@/models/utils/breadcrumb.interface";
import { MenuItems } from "@/models/utils/menu-items.interface";
import { authenticate } from "@/services/api/authenticate.service";
import { TransfersUsed } from "@/models/entities/transfers-used.interface";
import { overviewService } from "@/services/api/overview.service";
import TextHighlight from "vue-text-highlight";
import IBANTTT from "iban";
import {
  VMenuDivider,
  VMenuItem,
} from "vuelando/dist/types/models/utils/v-menu-item.interface";
import { userService } from "@/services/api/user.service";
import { PromotionPlans } from "@/models/entities/subscription-plan.interface";
import getDate from "date-fns/getDate/index";

const authModule = getModule(AuthModule, store);

const pageTitleModule = getModule(PageTitleModule, store);

const overlayModule = getModule(OverlayModule, store);

const searchBarModule = getModule(SearchBarModule, store);

const snackbarModule = getModule(SnackbarModule, store);

Vue.component("text-highlight", TextHighlight);

const DownloadComplete = () =>
  import(
    /* webpackChunkName: "DownloadComplete" */ "./Components/DownloadComplete.vue"
  );

type DrawerType = "default" | "warning" | "error-background" | "correct";

@Component({
  components: {
    "download-complete": DownloadComplete,
  },
})
export default class Home extends Vue {
  path = require("@/assets/CBILogoRed.png");

  private requiredRule = required;

  private drawer = true;
  private mini = true;
  private isFormValid = false;

  private categoryPurpose: CategoryPurpose[] = [];
  private categoryPurposeDescr = 23;

  private dateRequest = new Date().toFormat("yyyy-MM-dd");
  private dateRequestMin = new Date().toFormat("yyyy-MM-dd");

  private showAddOrderingSubject = false;
  private orderingSubject: OrderingSubjectView[] = [];
  private orderingSubjectID = 0;

  private file: File = null;
  private fileNextStep: File[] = null;

  private invalidRows: Beneficiary[] = [];
  private generateXml: GenerateXml = null;
  private dialog = false;
  private downloadComplete = false;

  private disableModifyRow = false;

  private totalBeneficiaries = "--";

  private loading = false;

  private showAlertWrongImport = false;

  // private value = 0;

  private beneficiaries: Beneficiary[] = [];
  private tableIsVisible = false;

  private uploadValue = true;

  private dialogConfirmModify = false;
  private pxDistance = "250px";

  private inEditing: Beneficiary = null;

  private foreignIban = false;
  private toggle = false;

  private descrDialogError = "";

  private deleteDialog = false;

  private removeForeignIban = false;

  private selectedWrong = true;
  private selectedDuplicates = true;

  private ineditingDialog = false;

  private consumptionLocked = false;
  private countBeneficiaries = 0;
  private money = {
    decimal: ",",
    thousands: ".",
    precision: 2,
  };

  private transfersUsed: TransfersUsed = {
    beneficiariesCount: 0,
    montlyBeneficiaries: 0,
  };

  private removeChanges: Beneficiary = {
    excelRowIndex: 0,
    subjectName: "",
    fiscalCode: "",
    iban: "",
    description: "",
    amount: null,
    locality: "",
    province: "",
    address: "",
    postalCode: "",
    city: "",
    country: "",
    isSelected: true,
    isValid: false,
    isRed: [],
  };

  private threedots: (VMenuItem | VMenuDivider)[] = [
    {
      label: this.$tc("home.DownloadTemplate"),
      iconName: "download",
      action: () => {
        this.downloadModel();
      },
    },
    "divider",
    {
      label: this.$tc("home.AllowForeignBeneficiaries"),
      iconName: "",
      action: () => {
        this.changedField();
      },
    },
  ];

  public breadcrumb: BreadCrumb[] = [
    {
      text: this.$tc("breadcrumb.Home"),
      disabled: true,
      href: "/home",
      current: true,
    },
  ];

  private fileExtension: string[] = [
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  private menuItems: MenuItems[] = [
    {
      label: this.$tc("home.AddToOtherBeneficiary"),
      iconName: "plus",
      action: () => {
        return this.uploadExcel(true);
      },
    },
    {
      label: this.$tc("home.ReplaceBeneficiary"),
      iconName: "switch",
      action: () => {
        return this.uploadExcel(false);
      },
    },
  ];

  private headersForeignIban: Header[] = [
    {
      text: "",
      value: "formValidator",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.SubjectName"),
      value: "subjectName",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.FiscalCode"),
      value: "fiscalCode",
      cellType: CellType.CUSTOM,
      rules: [generalFiscalCode],
    },
    {
      text: this.$tc("home.Iban"),
      value: "iban",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.Description"),
      value: "description",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Amount"),
      value: "amount",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Locality"),
      value: "locality",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Province"),
      value: "province",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Address"),
      value: "address",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.PostalCode"),
      value: "postalCode",
      cellType: CellType.CUSTOM,
    },
    {
      text: "    ",
      value: "actionBtn",
      cellType: CellType.CUSTOM,
    },
  ];

  private headers: Header[] = [];

  private headersNormal: Header[] = [
    {
      text: "",
      value: "formValidator",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.SubjectName"),
      value: "subjectName",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.FiscalCode"),
      value: "fiscalCode",
      cellType: CellType.CUSTOM,
      rules: [generalFiscalCode],
    },
    {
      text: this.$tc("home.Iban"),
      value: "iban",
      cellType: CellType.CUSTOM,
      rules: [required],
    },
    {
      text: this.$tc("home.Description"),
      value: "description",
      cellType: CellType.CUSTOM,
    },
    {
      text: this.$tc("home.Amount"),
      value: "amount",
      cellType: CellType.CUSTOM,
    },
    {
      text: "",
      value: "actionBtn",
      cellType: CellType.CUSTOM,
    },
  ];

  private tableOptions: DataTable<Beneficiary> = {
    key: "excelRowIndex",
    loading: false,
    search: "",
    actions: [
      {
        name: "edit",
        icon: "trash",
        tooltip: this.$tc("home.Delete"),
        size: "extra-small",
      } as TableAction,
    ],
    headers: this.headers,
    values: this.beneficiaries,
  };

  private tempAmount: string = null;

  //  {
  //      name: "duplicate",
  //      icon: "duplicate",
  //      tooltip: this.$tc("home.Duplicate"),
  //      size: "extra-small",
  //    } as TableAction,

  private get breadcrumbs(): BreadCrumb[] {
    return pageTitleModule.breadcrumb;
  }

  private get username(): string {
    return authModule.userName;
  }

  private get email(): string {
    return authModule.userEmail;
  }

  private get getWrongRows(): number {
    return this.beneficiaries.filter((x) => x.isValid == false).length;
  }

  //get duplicate iban
  private get getDoubleNumber(): number {
    // const doppi = this.beneficiaries.filter((x, y, z) => {
    //   return z.map((u) => u.iban).filter((n) => n == x.iban).length > 1;
    // });
    // return doppi.length;
    const doubled = this.beneficiaries.filter((x, y, z) => {
      return z.map((u) => u.iban).indexOf(x.iban) != y;
    });
    return doubled.length;
  }

  private get drawerType(): string {
    if (this.getWrongRows > 0 && this.tableIsVisible) {
      return "error-background mt-auto";
    }
    if (this.getDoubleNumber > 0 && this.tableIsVisible) {
      return "warning";
    }
    if (
      this.beneficiaries.filter((x) => x.isValid == false).length == 0 &&
      this.tableIsVisible
    ) {
      return "correct";
    }

    return "default";
  }

  private get totalAmount(): string {
    const total = this.beneficiaries.slice().reduce((aggr, el) => {
      const amount =
        el.amount != null && el.amount != ""
          ? el.amount.replaceAll(".", "").replaceAll(",", ".")
          : "0";

      const tempAmount = parseFloat(amount);
      aggr += !isNaN(tempAmount)
        ? tempAmount
        : parseFloat(this.tempAmount.replaceAll(".", "").replaceAll(",", "."));

      return aggr;
    }, 0);

    const ris = total.toLocaleString("it-IT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return ris != "0" || ris == null ? ris : "--";
  }

  async created(): Promise<void> {
    this.mini = false;
    searchBarModule.setVisibleAction(false);
    pageTitleModule.setBreadCrumb(this.breadcrumb);
    this.dateRequest = new Date().toISOString().split("T")[0];

    //hide scroll bar page
    window.addEventListener("beforeunload", this.onClose);
    document
      .getElementsByTagName("html")
      .item(0)
      .style.setProperty("overflow", "hidden");

    //get expiredPlans
    this.consumptionLocked = (await userService.GetConsumptionLocked()).data;

    //get used transfers
    overlayModule.showOverlay();
    await this.GetNumTransfersByMonth();

    //get data orderingSubject
    const orderingSubjects = await orderingSubjectService.readAll(
      authModule.userID
    );
    this.orderingSubject = orderingSubjects.data;
    if (this.orderingSubject.length > 0) {
      this.orderingSubjectID = this.orderingSubject[0].orderingSubjectID;
      this.orderingSubject.forEach((x) => {
        if (x.isDefault) {
          this.orderingSubjectID = x.orderingSubjectID;
        }
      });
    } else {
      this.showAddOrderingSubject = true;
    }

    //get data categoryPurposeSync
    const categoryPurpose = await categoryPurposeService.GetCategoryPurpose();
    this.categoryPurpose = categoryPurpose.data;

    //get headers
    this.headers = this.headersNormal;
    this.$set(this.tableOptions, "headers", this.headers);

    if (this.$vuetify.breakpoint.lgAndUp) {
      this.mini = false;
    }
  }

  private async logout(): Promise<void> {
    await authenticate.logout();
    this.$router.push({
      name: "login",
    });
  }

  private profileClicked(): void {
    this.$router.push({
      name: "account",
    });
  }

  private goHome() {
    this.$router.push({ name: "home" });
  }

  //add field for foreign iban
  private changedField(): void {
    let exists = false;
    if (this.foreignIban) {
      this.beneficiaries.forEach((x) => {
        if (
          x.locality != "" &&
          x.province != "" &&
          x.country != "" &&
          x.locality != ""
        ) {
          exists = true;
        }
      });
      if (exists) {
        this.removeForeignIban = true;
      } else {
        this.removeForeignField();
      }
    } else {
      this.headers = this.headersForeignIban;
      this.foreignIban = true;
      this.toggle = true;
    }

    this.$set(this.tableOptions, "headers", this.headers);
  }

  private removeForeignField(): void {
    this.headers = this.headersNormal;
    this.foreignIban = false;
    this.toggle = false;
    this.removeForeignIban = false;
    this.$set(this.tableOptions, "headers", this.headers);
  }

  private removeForeign(): void {
    this.beneficiaries.forEach((x) => {
      x.locality = "";
      x.province = "";
      x.country = "";
      x.locality = "";
    });
    this.headers = this.headersNormal;
    this.foreignIban = false;
    this.toggle = false;
    this.removeForeignIban = false;
    this.$set(this.tableOptions, "headers", this.headers);
  }

  private async GetNumTransfersByMonth(): Promise<void> {
    await overviewService.GetNumTransfersByMonth().then((x) => {
      this.transfersUsed = x.data;
    });
    if (this.transfersUsed) {
      this.transfersUsed.difference =
        this.transfersUsed.montlyBeneficiaries -
        this.transfersUsed.beneficiariesCount;
    }
  }

  private async fileExecute(): Promise<void> {
    var error = false;
    if (this.file != null) {
      try {
        overlayModule.showOverlay();
        //get elaboreate data from excel
        this.countBeneficiaries = 0;
        const x = await excelDataManagerService.GetDataByExcel(this.file);
        let newBene = this.validateRow(x.data);
        this.addBeneficiaryToArray(newBene);
        this.$set(this.tableOptions, "values", this.beneficiaries);
      } catch (e) {
        if (e.response.status == 409) error = true;
        snackbarModule.showSnackbar({
          message: this.$tc("home.BeneficiaryOutOfBound"),
          type: "error",
          timer: 5000,
        });
      }
    }
    this.parseAmount(this.beneficiaries);
    if (this.beneficiaries.length > 0) {
      this.tableIsVisible = true;
      searchBarModule.setVisibleAction(true);
    } else {
      if (error == false) {
        this.showAlertWrongImport = true;
        searchBarModule.setVisibleAction(false);
      }
    }
  }

  private async fileExecuteNextStep(): Promise<void> {
    if (this.fileNextStep[0] != null) {
      overlayModule.showOverlay();
      try {
        const x = await excelDataManagerService.GetDataByExcel(
          this.fileNextStep[0]
        );

        if (this.uploadValue) {
          let newBene = this.validateRow(x.data);

          this.parseAmount(newBene);

          this.addBeneficiaryToArray(newBene);
        } else {
          this.countBeneficiaries = 0;
          let newBene = this.validateRow(x.data);
          this.beneficiaries = [];

          this.addBeneficiaryToArray(newBene);

          this.parseAmount(this.beneficiaries);
        }
        this.$set(this.tableOptions, "values", this.beneficiaries);
        await this.$nextTick();
      } catch (e) {
        if (e.response.status == 409)
          snackbarModule.showSnackbar({
            message: this.$tc("home.BeneficiaryOutOfBound"),
            type: "error",
            timer: 5000,
          });
      }
    }
    if (this.beneficiaries.length > 0) {
      this.tableIsVisible = true;
      searchBarModule.setVisibleAction(true);
    } else {
      this.showAlertWrongImport = true;
      this.tableIsVisible = false;
      searchBarModule.setVisibleAction(false);
    }
  }

  private uploadExcel(value: boolean): void {
    (this.$refs.file as ZFileInput).triggerClick();
    this.uploadValue = value;
  }

  private async addBeneficiary(): Promise<void> {
    if (!this.beneficiaries.includes(this.inEditing)) {
      const beneficiary = {
        excelRowIndex: this.beneficiaries.length,
        subjectName: "",
        fiscalCode: "",
        iban: "",
        description: "",
        amount: null,
        locality: "",
        province: "",
        address: "",
        postalCode: "",
        city: "",
        country: "",
        duplicate: false,
        isSelected: true,
        isValid: false,
        isRed: [],
      };
      const index = this.beneficiaries.length;
      this.beneficiaries.push(beneficiary);
      this.inEditing = beneficiary;
      this.tableIsVisible = true;
      this.$set(this.tableOptions, "values", this.beneficiaries);
      this.drawer = true;
    }
  }

  private async downloadXml(): Promise<void> {
    this.loading = true;

    overlayModule.showOverlay();
    this.generateXml = {
      beneficiaries: this.beneficiaries,
      orderingSubjectID: this.orderingSubjectID,
      requestDate: this.dateRequest,
      categoryPurpose: this.categoryPurpose[this.categoryPurposeDescr - 1].code,
      userId: authModule.userID,
      firstTime: true,
    };
    try {
      let x = await downloadXmlService.GetDownloadXml(this.generateXml, true);

      var element = document.createElement("a");
      element.setAttribute(
        "href",
        "data:text/xml;charset=utf-8," + encodeURIComponent(x.data)
      );
      element.setAttribute("download", "module.xml");

      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.appendChild(element);
      this.downloadComplete = true;
      searchBarModule.setVisibleAction(false);
    } catch (e) {
      // this.errorBlocked = e.response.status == 403;
      if (e.response.status == 400)
        snackbarModule.showSnackbar({
          message: this.$tc("home.LicenceExpired"),
          type: "error",
          timer: 5000,
        });
      if (e.response.status == 409)
        snackbarModule.showSnackbar({
          message: this.$tc("home.BeneficiaryOutOfBound"),
          type: "error",
          timer: 5000,
        });
    }
    // this.startBuffer(100, x.data);
  }

  private newOrderingSubject(): void {
    this.$router.push({
      name: "account",
      params: {
        propOrderingSubject: "true",
        context: "orderingSubjectList",
      },
    });
  }

  private updateTotalBeneficiaries() {
    this.totalBeneficiaries = this.beneficiaries.length.toString();
  }

  private downloadModel(): void {
    window.open("https://cbifast.com/public/modello_cbi.xlsx");
  }

  //open for downloadXml
  private openDialog() {
    this.dialog = true;
  }

  private confirmDialog() {
    this.downloadXml();
    this.dialog = false;
  }

  private undoDialog() {
    this.dialog = false;
  }

  //open for modify on row
  private undoDialogRow() {
    //  this.dialogConfirmModify = false;
    this.removeChangesRow(this.removeChanges);
  }

  private confirmDialogRow() {
    this.dialogConfirmModify = false;
  }

  // private async startBuffer(maxVal: number, data?: string) {
  //   var random = Math.random() * 10 + 1;
  //   setTimeout(() => {
  //     this.value += 10;
  //     if (this.value < maxVal) {
  //       this.startBuffer(maxVal);
  //     } else if (maxVal == 100) {
  //       var element = document.createElement("a");
  //       element.setAttribute(
  //         "href",
  //         "data:text/xml;charset=utf-8," + encodeURIComponent(data)
  //       );
  //       element.setAttribute("download", "module.xml");

  //       element.style.display = "none";
  //       document.body.appendChild(element);
  //       element.click();
  //       document.body.appendChild(element);
  //       this.downloadComplete = true;
  //     }
  //   }, random * 50);
  // }

  private closeDownloadComplete(): void {
    this.downloadComplete = false;
    this.tableIsVisible = false;
    this.loading = false;
    this.beneficiaries = [];
    this.$set(this.tableOptions, "values", this.beneficiaries);
  }

  //select frong row
  private selectWrong(): void {
    if (this.selectedWrong) {
      this.selectedWrong = false;
      this.invalidRows = this.beneficiaries.filter((x) => x.isValid == false);
    } else {
      this.selectedWrong = true;
      this.invalidRows = [];
    }
  }

  //select double row
  private getDouble(): void {
    if (this.selectedDuplicates) {
      const doubled = this.beneficiaries.filter((x, y, z) => {
        return z.map((u) => u.iban).indexOf(x.iban) != y;
      });
      this.invalidRows = doubled;
      this.selectedDuplicates = false;
    } else {
      this.selectedDuplicates = true;
      this.invalidRows = [];
    }
  }

  private modifyRow(item: Beneficiary) {
    this.removeChanges = { ...item };
    this.disableModifyRow = true;
    this.tempAmount = item.amount;
    this.inEditing = item;
  }

  private removeChangesRow(item: Beneficiary) {
    let index = 0;
    for (let i = 0; i < this.beneficiaries.length; i++) {
      if (this.beneficiaries[i].excelRowIndex == item.excelRowIndex) {
        index = i;
        break;
      }
    }
    this.inEditing = null;
    // let index = this.beneficiaries.filter(
    //   (x) => x.excelRowIndex === item.excelRowIndex
    // );
    this.beneficiaries[index].iban = this.removeChanges.iban;
    this.beneficiaries[index].postalCode = this.removeChanges.postalCode;
    this.beneficiaries[index].city = this.removeChanges.city;
    this.beneficiaries[index].country = this.removeChanges.country;
    this.beneficiaries[index].subjectName = this.removeChanges.subjectName;
    this.beneficiaries[index].fiscalCode = this.removeChanges.fiscalCode;
    this.beneficiaries[index].description = this.removeChanges.description;
    this.beneficiaries[index].amount = this.removeChanges.amount;
    this.beneficiaries[index].locality = this.removeChanges.locality;
    this.beneficiaries[index].province = this.removeChanges.province;
    this.beneficiaries[index].address = this.removeChanges.address;
    this.beneficiaries[index].address = this.removeChanges.address;
    this.beneficiaries[index].isValid = this.removeChanges.isValid;
    this.beneficiaries[index].isRed = this.removeChanges.isRed;

    this.$set(this.tableOptions, "values", this.beneficiaries);
    this.disableModifyRow = false;
  }

  private checkIban(iban: string): boolean[] {
    var IBAN = IBANTTT;
    var valid: boolean[] = [];
    valid.push(IBAN.isValid(iban));
    return valid;
  }

  private saveChangesRow(arrayIndex: number) {
    let index = 0;
    //  this.descrDialogError = "";
    for (let i = 0; i < this.beneficiaries.length; i++) {
      if (this.beneficiaries[i].excelRowIndex == arrayIndex) {
        index = i;
        break;
      }
    }
    var IBAN = IBANTTT;
    this.beneficiaries[index].isValid = true;

    if (!IBAN.isValid(this.beneficiaries[index].iban)) {
      this.beneficiaries[index].isValid = false;
      // this.descrDialogError += "<br>" + this.$tc("home.Iban");
    }

    this.beneficiaries[index].amount = this.beneficiaries[index].amount.replace(
      "€",
      ""
    );

    if (
      this.beneficiaries[index].amount == "0" ||
      this.beneficiaries[index].amount == "0,00"
    ) {
      this.beneficiaries[index].isRed.push("amount");
      this.beneficiaries[index].isValid = false;
    }
    this.parseAmountSingle(this.beneficiaries[index]);
    Object.keys(this.beneficiaries[index]).forEach((y) => {
      if (
        (required(this.beneficiaries[index][y]) !== true &&
          y != "locality" &&
          y != "province" &&
          y != "address" &&
          y != "postalCode" &&
          y != "city" &&
          y != "country" &&
          y != "errorRef" &&
          y != "excelRowIndex" &&
          y != "isValid" &&
          y != "description" &&
          y != "fiscalCode") ||
        // (y == "iban" && iban(this.beneficiaries[index].iban) !== true) ||
        (y == "fiscalCode" &&
          this.beneficiaries[index][y] != null &&
          generalFiscalCode(this.beneficiaries[index][y]) !== true &&
          this.beneficiaries[index][y].length != 11)
      ) {
        this.beneficiaries[index].isValid = false;

        //   this.descrDialogError += "<br>";
        //   if (y == "fiscalCode")
        //     this.descrDialogError += this.$tc("home.FiscalCode");
        //   if (y == "iban") this.descrDialogError += this.$tc("home.Iban");
        //   if (y == "subjectName")
        //     this.descrDialogError += this.$tc("home.SubjectName");
      }
    });
    if (this.beneficiaries[index].isValid == true) {
      this.disableModifyRow = false;
      this.inEditing = null;
      this.beneficiaries[index].isRed = [];
      if (!this.beneficiaries[index].iban.startsWith("IT")) {
        this.headers = this.headersForeignIban;
        this.foreignIban = true;
        this.toggle = true;
        this.$set(this.tableOptions, "headers", this.headers);
      }
    } else {
      snackbarModule.showSnackbar({
        message: this.$tc("home.RowErrorSnackbar"),
        type: "error",
        timer: 5000,
      });
      // this.dialogConfirmModify = true;
    }
  }

  //prevent from close tab
  onClose(event: BeforeUnloadEvent): void {
    if (
      this.beneficiaries != null &&
      this.beneficiaries.length != 0 &&
      this.$router.currentRoute.path == "/home"
    ) {
      event.preventDefault();
      event.returnValue = "Are you sure you want to exit?";
    }
  }

  private parseAmount(beneficiaries: Beneficiary[]) {
    let ris = "";
    beneficiaries.forEach((x) => {
      if (x.iban != null) {
        x.iban = x.iban.trim().replaceAll(/\s/g, "");
      }
      if (x.amount != null && x.amount != "") {
        let obj = x.amount.replaceAll(",", ".");
        ris = parseFloat(obj).toLocaleString("it-IT", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      } else {
        ris = "0";
      }
      x.amount = ris;
    });
  }

  private parseAmountSingle(beneficiaries: Beneficiary) {
    let ris = "";
    if (beneficiaries.amount != null && beneficiaries.amount != "") {
      if (beneficiaries.iban != null) {
        beneficiaries.iban = beneficiaries.iban.trim().replaceAll(/\s/g, "");
      }
      ris = parseFloat(
        beneficiaries.amount.replaceAll(".", "").replaceAll(",", ".")
      ).toLocaleString("it-IT", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    } else {
      ris = "0";
    }
    beneficiaries.amount = ris;
  }

  //dynamic validation of row
  private validateRow(beneficiaries: Beneficiary[]): Beneficiary[] {
    var IBAN = IBANTTT;
    let newBene = beneficiaries.map((x, i) => {
      x.isValid = true;
      x.isRed = [];
      if (x.amount == "0" || x.amount == "0,00") {
        x.isRed.push("amount");
        x.isValid = false;
      }

      if (!IBAN.isValid(x.iban)) {
        x.isRed.push("iban");
        x.isValid = false;
        this.descrDialogError += "<br>" + this.$tc("home.Iban");
      }
      for (var y of Object.keys(x)) {
        if (
          (required(x[y]) !== true &&
            y != "locality" &&
            y != "province" &&
            y != "address" &&
            y != "postalCode" &&
            y != "city" &&
            y != "country" &&
            y != "errorRef" &&
            y != "excelRowIndex" &&
            y != "isValid" &&
            y != "description" &&
            y != "fiscalCode") ||
          // (y == "iban" && iban(x[y]) !== true) ||
          (y == "fiscalCode" &&
            x[y] != null &&
            generalFiscalCode(x[y]) !== true &&
            x[y].length != 11)
        ) {
          x.isRed.push(y.toString());
          x.isValid = false;
        }
      }

      x.excelRowIndex = this.countBeneficiaries;
      this.countBeneficiaries++;
      return x;
    });
    return newBene;
  }

  //add beneficary to array
  private addBeneficiaryToArray(beneficiaries: Beneficiary[]) {
    beneficiaries.forEach((beneficiary: Beneficiary) => {
      if (!beneficiary.iban.startsWith("IT")) {
        this.headers = this.headersForeignIban;
        this.foreignIban = true;
        this.toggle = true;
        this.$set(this.tableOptions, "headers", this.headers);
      }
      this.beneficiaries.push(beneficiary);
    });
  }

  private deleteRow() {
    this.invalidRows.forEach((beneficiary: Beneficiary) => {
      for (let i = 0; i < this.beneficiaries.length; i++) {
        if (this.beneficiaries[i].excelRowIndex == beneficiary.excelRowIndex) {
          this.beneficiaries.splice(i, 1);
        }
      }
    });
    this.invalidRows = [];
    this.deleteDialog = false;
    if (this.beneficiaries.length == 0) {
      this.tableIsVisible = false;
      searchBarModule.setVisibleAction(false);
    }
  }

  //   private duplicateRows() {
  //     this.invalidRows.forEach((beneficiary: Beneficiary) => {
  //       this.beneficiaries.push({ ...beneficiary });
  //       this.beneficiaries[this.beneficiaries.length - 1].excelRowIndex =
  //         this.beneficiaries.length + 1;
  //     });
  //     this.invalidRows = [];
  //   }

  get globalSearch(): string {
    return searchBarModule.search;
  }

  @Watch("globalSearch")
  globalSearchChanged(): void {
    this.tableOptions.search = this.globalSearch;
  }

  private customSearch(
    value: string | number,
    globalSearch: string | null,
    beneficiaries: Beneficiary
  ): boolean {
    const src = globalSearch.toLowerCase().trim();
    if (
      (beneficiaries.subjectName != null &&
        beneficiaries.subjectName.toLowerCase().includes(src)) ||
      (beneficiaries.fiscalCode != null &&
        beneficiaries.fiscalCode.toString().toLowerCase().includes(src)) ||
      (beneficiaries.iban != null &&
        beneficiaries.iban.toString().toLowerCase().includes(src)) ||
      (beneficiaries.description != null &&
        beneficiaries.description.toString().toLowerCase().includes(src)) ||
      (beneficiaries.amount != null &&
        beneficiaries.amount.toString().toLowerCase().includes(src)) ||
      (beneficiaries.locality != null &&
        beneficiaries.locality.toLowerCase().includes(src)) ||
      (beneficiaries.province != null &&
        beneficiaries.province.toLowerCase().includes(src)) ||
      (beneficiaries.address != null &&
        beneficiaries.address.toLowerCase().includes(src)) ||
      (beneficiaries.postalCode != null &&
        beneficiaries.postalCode.toLowerCase().includes(src)) ||
      (beneficiaries.city != null &&
        beneficiaries.city.toLowerCase().includes(src)) ||
      (beneficiaries.country != null &&
        beneficiaries.country.toLowerCase().includes(src))
    ) {
      return true;
    }
    return false;
  }

  private log(item) {
    console.log(item.amount);
  }
}
